body {
  margin: 0;
  padding: 20px;
  background-color: #f1f1f1;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  appearance: none;
  margin: 0px 10px 20px 10px;
  background: #3d3d3d;
  border: 0;
  padding: 10px 20px;
  color: white;
  cursor: pointer;
}

button:hover {
  background: #575757;
}

button:active {
  background: #7c7c7c;
}

h6 {
  font-weight: normal;
  color: #7c7c7c;
}

a {
  color: #158bda;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
