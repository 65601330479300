.App {
  text-align: center;
}
.wrapper {
  text-align: center;
  margin: 0 auto;
}

.unity-container {
  position: relative;
  width: 100%;
  padding-bottom: 60%;
  background-color: rgb(145, 145, 145);
}

.loading-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgb(51, 48, 48);
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress-bar {
  width: 100px;
  height: 10px;
  background-color: rgb(99, 99, 99);
  border-radius: 5px;
  overflow: hidden;
}

.progress-bar-fill {
  height: 10px;
  background-color: rgb(190, 190, 190);
  transition: width 0.5s ease;
}

.unity-canvas {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
.MuiButton-startIcon {
  display: none !important;
}
